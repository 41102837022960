// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-img-header {
  max-width: 50px;
  margin-top: 20px;
}

.boldy {
  font-weight: bold;
}

.admins {
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EAGA,gBAAA;AADJ;;AAGA;EACI,iBAAA;AAAJ;;AAEA;EACI,kBAAA;AACJ","sourcesContent":[".menu-img-header{\n    max-width: 50px;\n    // margin-left: auto;\n    // margin-right: auto;\n    margin-top: 20px;\n}\n.boldy {\n    font-weight: bold;\n}\n.admins {\n    font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
