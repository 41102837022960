// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultLang: 'en',
  domain: 'https://yoga-81ec8.firebaseapp.com',
  secr: 'q4z15oKQn6JCh98pKe9Q67wjo23vyRlq3V',
  starterTest:
    'https://us-central1-dojocontact-91973.cloudfunctions.net/starterTest',
  // starterTest: 'http://localhost:5002/dojocontact-91973/us-central1/starterTest',
  googleclientid:
    '639273381290-g7cehfnsr5t2icleuufjhhbd7i0rpd9o.apps.googleusercontent.com',
  version: 1003,
  firebaseConfig: { // PROD
    apiKey: 'AIzaSyBN8drnAGZWel1RarlN2qZIlh8S6krNgWY',
    authDomain: 'yoga-81ec8.firebaseapp.com',
    projectId: 'yoga-81ec8',
    storageBucket: 'yoga-81ec8.appspot.com',
    messagingSenderId: '722110875645',
    appId: '1:722110875645:web:5850518af3483ddca6bb94',
    measurementId: 'G-GH54Z6STQP',
  },
  // firebaseConfig: { // STAGING
  //   apiKey: 'AIzaSyDMvMO6GJguDz5Dym9FJ8BIj67_M4Ostos',
  //   authDomain: 'dojocontact-staging.firebaseapp.com',
  //   projectId: 'dojocontact-staging',
  //   storageBucket: 'dojocontact-staging.appspot.com',
  //   messagingSenderId: '1039881153645',
  //   appId: '1:1039881153645:web:0d9f0c4874f7b5c9742364',
  //   measurementId: 'G-FV5KPRFYX6'
  // }
};

export const currencies = [
  { currency: 'Euro', code: 'EUR' },
  { currency: 'United States Dollar', code: 'USD' },
  { currency: 'British Pound', code: 'GBP' },
  { currency: 'Argentine Peso', code: 'ARS' },
  { currency: 'Armenian Dram', code: 'AMD' },
  { currency: 'Aruban Florin', code: 'AWG' },
  { currency: 'Australian Dollar', code: 'AUD' },
  { currency: 'Brazilian Real', code: 'BRL' },
  { currency: 'Bulgarian Lev', code: 'BGN' },
  { currency: 'Canadian Dollar', code: 'CAD' },
  { currency: 'Chilean Peso', code: 'CLP' },
  { currency: 'Chinese Renminbi Yuan', code: 'CNY' },
  { currency: 'Colombian Peso', code: 'COP' },
  { currency: 'Costa Rican Colón', code: 'CRC' },
  { currency: 'Croatian Kuna', code: 'HRK' },
  { currency: 'Czech Koruna', code: 'CZK' },
  { currency: 'Danish Krone', code: 'DKK' },
  { currency: 'Egyptian Pound', code: 'EGP' },
  { currency: 'Hong Kong Dollar', code: 'HKD' },
  { currency: 'Hungarian Forint', code: 'HUF' },
  { currency: 'Icelandic Króna', code: 'ISK' },
  { currency: 'Indian Rupee', code: 'INR' },
  { currency: 'Indonesian Rupiah', code: 'IDR' },
  { currency: 'Israeli New Sheqel', code: 'ILS' },
  { currency: 'Japanese Yen', code: 'JPY' },
  { currency: 'Kazakhstani Tenge', code: 'KZT' },
  { currency: 'Macedonian Denar', code: 'MKD' },
  { currency: 'Malaysian Ringgit', code: 'MYR' },
  { currency: 'Mexican Peso', code: 'MXN' },
  { currency: 'Moldovan Leu', code: 'MDL' },
  { currency: 'Moroccan Dirham', code: 'MAD' },
  { currency: 'Nepalese Rupee', code: 'NPR' },
  { currency: 'New Taiwan Dollar', code: 'TWD' },
  { currency: 'New Zealand Dollar', code: 'NZD' },
  { currency: 'Nigerian Naira', code: 'NGN' },
  { currency: 'Norwegian Krone', code: 'NOK' },
  { currency: 'Pakistani Rupee', code: 'PKR' },
  { currency: 'Peruvian Nuevo Sol', code: 'PEN' },
  { currency: 'Philippine Peso', code: 'PHP' },
  { currency: 'Polish Złoty', code: 'PLN' },
  { currency: 'Qatari Riyal', code: 'QAR' },
  { currency: 'Romanian Leu', code: 'RON' },
  { currency: 'Saudi Riyal', code: 'SAR' },
  { currency: 'Serbian Dinar', code: 'RSD' },
  { currency: 'Singapore Dollar', code: 'SGD' },
  { currency: 'South African Rand', code: 'ZAR' },
  { currency: 'South Korean Won', code: 'KRW' },
  { currency: 'Sri Lankan Rupee', code: 'LKR' },
  { currency: 'Surinamese Dollar', code: 'SRD' },
  { currency: 'Swedish Krona', code: 'SEK' },
  { currency: 'Swiss Franc', code: 'CHF' },
  { currency: 'Thai Baht', code: 'THB' },
  { currency: 'Turkish Lira', code: 'TRY' },
  { currency: 'Ugandan Shilling', code: 'UGX' },
  { currency: 'Ukrainian Hryvnia', code: 'UAH' },
  { currency: 'United Arab Emirates Dirham', code: 'AED' },
  { currency: 'Uruguayan Peso', code: 'UYU' },
  { currency: 'Vietnamese Đồng', code: 'VND' },
];
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
