/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable, NgZone } from '@angular/core';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ApiService } from './api.service';
import { Owner, User } from '../interfaces/interfaces';
import * as firestore from 'firebase/firestore';
import { UserPage } from '../pages/admin/user/user.page';
import { isPlatform, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // private _userData: any; // Save logged in user data

  private _userData: User = null;

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private api: ApiService
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
        this.getUserData(user);
        JSON.parse(localStorage.getItem('user')!);
      } else {
        localStorage.setItem('user', 'null');
        JSON.parse(localStorage.getItem('user')!);
      }
    });
    this.api.versionCheck();
  }

  get owner(): boolean {
    return this.userData ? this.userData.owner : false;
  }

  public get userData(): User {
    return this._userData;
  }
  public set userData(data: User) {
    this._userData = data;
  }
  gjd(user) {
    const url = 'users/' + user.uid;
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(url);
    return userRef.get().toPromise();
  }
  getUserData(user) {
    const url = 'users/' + user.uid;
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(url);
    return userRef.get().subscribe((doc) => {
      if (doc.exists) {
        console.log('get user from firestore', doc.data());
        const userData: User = {
          name: doc.data().name,
          owner: doc.data().owner,
          uid: doc.data().uid,
          email: doc.data().email,
          photoURL: doc.data().photoURL,
          phone: doc.data().phone,
          emailVerified: doc.data().emailVerified,
          clubs: doc.data().clubs,
          address: doc.data().address,
          displayName: doc.data().displayName,
          metric: doc.data().metric
        };
        localStorage.setItem('user', JSON.stringify(this.userData));
        localStorage.setItem('djcmetric', doc.data().metric);
        this.userData = userData;
        const token = localStorage.getItem('djcregistrationToken');
        if (token) {
          let tokenarr = userData.pushTokens;
          if (tokenarr) {
            if (!tokenarr.includes(token)) {
              tokenarr.push(token);
            }
          } else {
            tokenarr = [token];
          }

          userData.pushTokens = tokenarr;
        }
        console.log('this.userData', this.userData);
        const loc = window.location.href;
        // if (!loc.includes('/home')) {
        //   this.router.navigateByUrl('/home', { replaceUrl: true });
        //   setTimeout(() => {
        //     location.reload();
        //   });
        // }
        if (userData.owner) {
          this.api.setMembers(this.userData.clubs[0]);
        }
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
      }
    });
  }

  // Sign in with email/password
  SignIn(email: string, password: string) {
    // console.log('signing in', email, password);
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(async (result) => {
        this.getUserData(result.user);
        await this.gjd(result.user)
          .then(() => {
            console.log('bl;ah di dbal');
            this.SetUserData(result.user, true).then(() => {
              this.router.navigateByUrl('/my', { replaceUrl: true });
            });
          })
          .catch((e) => {
            window.alert(e.message);
            console.log('not vewwi wel');
          });
        // console.log('login succesful', result);
        // this.SetUserData(result.user, true).then(() => {
        //   this.router.navigateByUrl('/my', { replaceUrl: true });
        // });
        // this.ngZone.run(() => {

        // });
      })
      .catch((error) => {
        console.log('1', error.message);
        // this.router.navigateByUrl('/my', { replaceUrl: true });
        window.alert(error.message);
        // window.alert(error.message);
      });
  }
  // Sign up with email/password
  SignUp(user) {
    const email = user.email;
    const password = user.password;
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        const usr: any = result.user;
        user.uid = usr.uid;
        user.displayName = usr.displayName;
        user.phone = '';
        user.photoURL = usr.photoURL;
        user.emailVerified = usr.emailVerified;
        user.clubs = user.clubs ? user.clubs : [];
        user.pushTokens = [];
        const token = localStorage.getItem('djcregistrationToken');
        if (token) {
          user.pushTokens = [token];
        }
        this.SendVerificationMail();
        this.SetUserData(user);
      })
      .catch((error) => {
        console.log('2', error.message);
        // window.alert(error.message);
      });
  }
  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        // this.router.navigate(['/login']);
      });
  }
  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.api.presentToast('Check your inbox for password reset', 'primary');
        // window.alert('Wachtwoord reset aangevraagd, controleer je inbox.');
      })
      .catch((error) => {
        console.log('3', error.message);
        // window.alert(error);
        this.api.presentToast('error password reset', 'danger');
      });
  }
  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = this.userData;
    return user !== null;
  }

  // Sign in with Google
  GoogleAuth(user?, stay?) {
    return this.AuthLogin(new auth.GoogleAuthProvider(), user, stay).then(
      (res: any) => {
        if (res) {
          if (!stay) {
            this.router.navigateByUrl('/home', { replaceUrl: true });
            console.log('user found', res);
          }
        }
      }
    );
  }
  // Sign in with Google
  FacebookAuth(user?, stay?) {
    return this.AuthLogin(new auth.FacebookAuthProvider(), user, stay).then(
      (res: any) => {
        if (res) {
          if (!stay) {
            this.router.navigateByUrl('/home', { replaceUrl: true });
            console.log('user found', res);
          }
        }
      }
    );
  }

  // Auth logic to run auth providers
  AuthLogin(provider: any, user?, stay?) {
    console.log('SHOULD ONLY ');
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        if (!stay) {
          this.ngZone.run(() => {
            this.router.navigateByUrl('/home', { replaceUrl: true });
          });
        }
        console.log('authenticated o2', result.user);

        const strQAr = JSON.stringify(result.user);
        console.log('strQAr', strQAr);

        const oldUser: any = JSON.parse(strQAr);
        const newUser: User = {
          name: '',
          address: '',
          displayName: '',
          email: '',
          photoURL: '',
          emailVerified: false,
          uid: '',
          clubs: [],
          phone: '',
          pushTokens: [],
          metric: 'metric'
        };
        // console.log('authenticated 1', usr);
        console.log('authenticated 2', oldUser);
        newUser.name = oldUser.name ? oldUser.name : oldUser.displayName;
        newUser.displayName = oldUser.displayName ? oldUser.displayName : '';
        newUser.email = oldUser.email;
        newUser.photoURL = oldUser.photoURL;
        newUser.emailVerified = oldUser.emailVerified;
        newUser.uid = oldUser.uid;
        newUser.phone = oldUser.phoneNumber;
        newUser.metric = oldUser.metric;
        const token = localStorage.getItem('djcregistrationToken');
        let tokenarr = [];
        if (token) {
          if (this.userData.pushTokens) {
            tokenarr = this.userData.pushTokens;
          }
          const oldarr = tokenarr;
          if (tokenarr) {
            if (!tokenarr.includes(token)) {
              tokenarr.push(token);
            }
          } else {
            tokenarr = [token];
          }
        }
        newUser.pushTokens = tokenarr;
        console.log('newUser', newUser);
        this.SetUserData(newUser);
      })
      .catch((error) => {
        console.log('4', error.message);
        console.log('ee', error);
        // window.alert(error);
        if (!stay) {
          this.router.navigateByUrl('/home', { replaceUrl: true });
        }
      });
  }
  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: any, login?) {
    if (login) {
      console.log('*** loggin in ***', user, this.userData);
    }
    const token = localStorage.getItem('djcregistrationToken');
    let tokenarr = [];
    if (token) {
      if (this.userData.pushTokens) {
        tokenarr = this.userData.pushTokens;
      }
      const oldarr = tokenarr;
      if (tokenarr) {
        if (!tokenarr.includes(token)) {
          tokenarr.push(token);
        }
      } else {
        tokenarr = [token];
      }
      user.pushTokens = tokenarr;
    }

    const updatedAt = firestore.serverTimestamp();
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    let displayName = user.name ? user.name : user.email;
    if (user.displayName) {
      displayName = user.displayName;
    }
    let owner = false;
    let metric = 'metric';
    try {
      if (this.userData.owner) {
        owner = true;
      }
    } catch (error) {
      // shave my balls
    }
    try {
      if (this.userData.metric) {
        metric = this.userData.metric;
      }
    } catch (error) {
      // shave my balls
    }
    let userData: any;
    if (login) {
      userData = this.userData;
      userData.pushTokens = tokenarr;
      console.log('*** returning ***', userData);
    } else {
      userData = {
        name: user.name ? user.name : user.email,
        uid: user.uid,
        email: user.email,
        clubs: user.clubs ? user.clubs : [],
        phone: user.phone ? user.phone : '',
        address: user.address ? user.address : '',
        photoURL: user.photoURL ? user.photoURL : '',
        emailVerified: user.emailVerified,
        metric,
        displayName,
        owner,
        updatedAt,
        pushTokens: user.pushTokens?user.pushTokens:tokenarr,
      };
    }
    this.api.setUser(userData);
    this.userData = userData;
    console.log('*** saving ***', userData);
    return userRef.set(userData, {
      merge: true,
    });
  }
  removeAccount() {
    return this.api.deleteUser(this.userData.uid);
  }
    // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.clear();
      this.api.setUser(null);
      this.router.navigateByUrl('/home', { replaceUrl: true });
      setTimeout(() => {
        location.reload();
      });
    });
  }
}
