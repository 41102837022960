import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Club } from '../interfaces/interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public themeColor = [
    { name: 'Default', class: 'default' },
    { name: 'Dark', class: 'dark-theme' },
    { name: 'Purple', class: 'purple' },
  ];
  public participants: Observable<any>;
  public participantsArray: any[];
  private club: Club;
  private myUnpaid: any[] = [];
  constructor(
    private translateService: TranslateService,
    private api: ApiService
  ) {}
  public get unpaid() {
    return this.myUnpaid.length;
  }

  metric() {
    const metric = localStorage.getItem('djcmetric');
    if (metric) {
      return metric;
    }
    return 'metric';
  }

  even(nr): boolean {
    // check if the number is even
    if (nr % 2 === 0) {
      return true;
    } else {
      return false;
    }
  }

  setDefaultLanguage(val: string) {
    localStorage.setItem('3byDefaultLanguage', val);
  }

  getDefaultLanguage(): string {
    return localStorage.getItem('3byDefaultLanguage');
  }

  setClub(club) {
    this.club = club;
    if (this.club.logo) {
      localStorage.setItem('3byDefaultLogo', this.club.logo);
    }
  }

  getClubLogo() {
    const logoStored = localStorage.getItem('3byDefaultLogo');
    try {
      return this.club.logo;
    } catch (error) {
      if (logoStored) {
        return logoStored;
      }
      return '../assets/img/1x1.png';
    }
  }

  async presentToast(message, color?) {
    if (!color) {
      /**
       * "primary", "secondary", "tertiary", "success", "warning", "danger", "light", "medium", and "dark".
       */
      color = 'light';
    }
    await this.api.presentToast(message, color);
  }

  getUser(id) {
    try {
      return this.participantsArray.find((x) => x.id === id);
    } catch (error) {
      return id;
    }
  }

  firebaseDate(dat: any) {
    const numDate = new Date(dat * 1000);
    return numDate;
  }

  openPayments(club, uid) {
    this.api.getOpenPayments(club, uid).subscribe((data) => {
      this.myUnpaid = data as any[];
    });
  }

  setUsers(club?) {
    if (club) {
      this.club = club;
    }
    this.participants = this.api.getUsersPerClub(this.club);
    this.participants.subscribe((data) => {
      this.participantsArray = data as any[];
    });
  }

  todate(todate) {
    // transform Firebase Timestamp
    return new Date(todate.seconds * 1000);
  }

  tojson(str) {
    return JSON.stringify(str);
  }

  tojsonMarkup(str) {
    return JSON.stringify(str, null, 2);
  }

  translate(item: string) {
    // return translated string
    const translation = this.translateService.instant(item);
    return translation;
  }
  downloadCsv(subber, filename?) {
    subber.subscribe((data) => {
      const scvdata = data as any[];
      if (filename) {
        this.downloadFile(data, filename);
      } else {
        this.downloadFile(data);
      }
    });
  }

  downloadFile(data: any, filename?) {
    let fname = 'exportedCSV';
    if (filename) {
      fname = filename;
    }
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = fname + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
