import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Class, Club, News, Owner, User } from '../interfaces/interfaces';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { environment } from '../../environments/environment';
import { Platform, ToastController } from '@ionic/angular';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private myUser: Observable<User>;
  private myNews: Observable<any>;
  private myMembers: Observable<any>;
  private myUnpaid: any[];
  private platform: Platform;

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient,
    private toastController: ToastController
  ) {}

  public get news() {
    return this.myNews;
  }

  public get user() {
    return this.myUser;
  }
  public get users() {
    return this.myUser;
  }

  public get members() {
    return this.myMembers;
  }

  addChatMessage(chat) {
    return this.firestore.collection<any>('messages').add(chat);
  }

  async setRemoval(user) {
    await this.firestore.collection<any>('removals').add(user);
  }

  checkForStarters(email): Promise<any> {
    const secr = environment.secr;
    const codeurl = environment.starterTest;
    const body = {
      email,
      secr,
    };
    return this.http.post<any>(codeurl, body).toPromise();
  }

  run3Sundays(diffydays, userid, classid) {
    const functions = getFunctions();
    const addMessage = httpsCallable(functions, 'sundayDiffRun');
    addMessage({ userid, diffydays, classid }).then(
      (result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        console.log('function ran', result);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  versionCheck() {
    this.getItem('version/minimal').subscribe(
      (data) => {
        if (data.version > environment.version) {
          if (
            window.confirm(
              'Old version of the app, please visit the appstore for an update.'
            )
          ) {
            if (this.platform.is('android')) {
              window.location.href = 'https://play.google.com/';
            }
            if (this.platform.is('ios')) {
              window.location.href = 'https://www.apple.com/app-store/';
            }
          }
        }
        // console.log('version checked, minimal requirements set');
      },
      (e) => {
        alert(
          'miniumal version cannot be determined, check the appstore for an update\n' +
            e
        );
      }
    );
  }

  setMembers(club) {
    this.myMembers = this.getUsersPerClub(club);
    console.log('user is owner, members set');
  }

  setUser(user) {
    this.myUser = user;
  }

  getOwnerDocument(uid: string): Observable<Owner> {
    return this.firestore
      .collection<Owner>('owners', (ref) => ref.where('uid', '==', uid))
      .valueChanges()
      .pipe(map((item: any) => item));
  }
  getClub(id): Observable<Club> {
    const itemDoc = this.firestore.doc<any>('clubs/' + id);
    return itemDoc.valueChanges();
  }
  getRooms(club: string): Observable<any> {
    return this.firestore
      .collection<User>('rooms', (ref) => ref.where('club', '==', club))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }
  getClubs(): Observable<any> {
    return this.firestore
      .collection<User>('clubs', (ref) => ref.orderBy('name', 'asc'))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }
  getClubsSorted(partial): Observable<any> {
    return this.firestore
      .collection<User>('clubs', (ref) =>
        ref
          .orderBy('name', 'asc')
          .where('name', '>=', partial)
          .where('name', '<=', partial + '\uf8ff')
      )
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }

  getActivitiesClassWeek(nclass) {
    return this.firestore
      .collection<User>('activities', (ref) =>
        ref
          .where('class', '==', nclass)
          .orderBy('startdate', 'asc')
          .orderBy('week', 'asc')
          .orderBy('uid', 'asc')
      )
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }

  getActivitiesPersonWeek(uid, week) {
    return this.firestore
      .collection<User>('activities', (ref) =>
        ref.where('uid', '==', uid).where('week', '==', week)
      )
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }
  getActivitiesPersonAll(uid, week) {
    return this.firestore
      .collection<User>('activities', (ref) =>
        ref.where('uid', '==', uid).where('week', '>=', week)
      )
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }

  getWeeks(): Observable<any> {
    return this.firestore
      .collection<User>('week', (ref) => ref.orderBy('currentDate', 'desc'))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }

  getUsersPerClub(club): Observable<any> {
    console.log('find users per club', club);
    return this.firestore
      .collection<User>('users', (ref) => ref.orderBy('name', 'asc').where('clubs', 'array-contains', club))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }
  getUsersSortable(sorter, dir): Observable<any> {
    console.log('find all users');
    return this.firestore
      .collection<User>('users', (ref) => ref.orderBy(sorter, dir))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }
  getUsersSortableSearch(
    sorter: string,
    dir: any,
    value: string,
    field: string
  ): Observable<any> {
    console.log('find all users:', sorter, dir, field, value);
    return this.firestore
      .collection<User>('users', (ref) =>
        ref
          .orderBy(sorter, dir)
          .where(field, '>=', value)
          .where(field, '<=', value + '\uf8ff')
      )
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }
  getUser(id) {
    return this.firestore
      .collection<User>('users', (ref) => ref.where('id', '==', id))
      .valueChanges()
      .pipe(map((item: any) => item));
  }
  getClasses(club: string): Observable<any> {
    return this.firestore
      .collection<Class>('classes', (ref) =>
        ref
          .where('club', '==', club)
          .orderBy('day', 'asc')
          .orderBy('starttime', 'asc')
      )
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }
  getChats(club: string): Observable<any> {
    return this.firestore
      .collection<Class>('chats', (ref) =>
        ref.where('club', '==', club).orderBy('modifiedAt', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }
  getMessages(chat: string): Observable<any> {
    return this.firestore
      .collection<Class>('messages', (ref) =>
        ref.where('chat', '==', chat).orderBy('createdAt', 'asc')
      )
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }

  getClassesPersonal(club: string, uid: string): Observable<any> {
    console.log('looking for club', club);
    console.log('and uid', uid);
    console.log('in classes');

    return this.firestore
      .collection<Class>('classes', (ref) =>
        ref
          .where('club', '==', club)
          .where('permitted', 'array-contains', uid)
          .orderBy('day', 'asc')
          .orderBy('starttime', 'asc')
      )
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }

  setNews(clubarray, sorter, dir) {
    this.myNews = this.firestore
      .collection<News>('news', (ref) =>
        ref.where('club', '==', clubarray[0]).orderBy(sorter, dir)
      )
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }

  getSortableAdminArray(item, sorter, dir?) {
    const sortdirection = dir ? dir : 'asc';
    return this.firestore
      .collection<any>(item, (ref) => ref.orderBy(sorter, sortdirection))
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }
  getSortableArray(item, club, sorter, dir?) {
    const sortdirection = dir ? dir : 'asc';
    return this.firestore
      .collection<any>(item, (ref) =>
        ref.where('club', '==', club).orderBy(sorter, sortdirection)
      )
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }
  getPersonalInvoices(club, uid) {
    return this.firestore
      .collection<any>('invoices', (ref) =>
        ref
          .where('club', '==', club)
          .where('uid', '==', uid)
          .orderBy('status', 'asc')
          .orderBy('date', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }

  getOpenPayments(club, uid) {
    return this.firestore
      .collection<any>('invoices', (ref) =>
        ref
          .where('club', '==', club)
          .where('uid', '==', uid)
          .where('status', '==', 'open')
      )
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }

  getItem(path: string) {
    const itemDoc = this.firestore.doc<any>(path);
    return itemDoc.valueChanges();
  }

  getSnitem(path: string) {
    const itemDoc = this.firestore.doc<any>(path);
    return itemDoc.snapshotChanges();
  }

  setItem(path, id, item) {
    console.log(id);
    if (id && id !== 'new') {
      return this.firestore.doc(path + '/' + id).update(item);
    } else {
      return this.firestore
        .collection<any>(path)
        .add(item)
        .then(() => {
          console.log('saved item', item);
        })
        .catch((e) => {
          // alert('error saving:\n' + e);
          this.presentToast('save error :(', 'danger');
          console.log('error item', path,id, e, item);
        });
    }
  }

  deleteUser(id) {
    return this.firestore.collection('users').doc(id).delete();
  }

  setItemWithReturn(path, id, item): Promise<any> {
    if (id && id !== 'new') {
      return this.firestore.doc(path + '/' + id).update(item);
    } else {
      return this.firestore.collection<any>(path).add(item);
    }
    // return this.getItem(path + '/' + id);
  }

  getClubByCode(code) {
    return this.firestore
      .collection<News>('clubs', (ref) =>
        ref.where('code', '==', code).limit(1)
      )
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c: any) => ({
            id: c.payload.doc.id,
            ...c.payload.doc.data(),
          }))
        )
      );
  }

  public async presentToast(message, color?) {
    if (!color) {
      /**
       * "primary", "secondary", "tertiary", "success", "warning", "danger", "light", "medium", and "dark".
       */
      color = 'light';
    }
    const toast = await this.toastController.create({
      message,
      duration: 6000,
      position: 'top',
      color,
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }
}
