import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard'; // secure pages
import {
  canActivate,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';

// Send unauthorized users to login
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/']);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/auth/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./pages/auth/signup/signup.module').then(
        (m) => m.SignupPageModule
      ),
  },
  {
    path: 'signup/:email',
    loadChildren: () =>
      import('./pages/auth/signup/signup.module').then(
        (m) => m.SignupPageModule
      ),
  },
  {
    path: 'my',
    loadChildren: () =>
      import('./pages/auth/my/my.module').then((m) => m.MyPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'club',
    loadChildren: () =>
      import('./pages/club/club/club.module').then((m) => m.ClubPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'room',
    loadChildren: () =>
      import('./pages/club/room/room.module').then((m) => m.RoomPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'class',
    loadChildren: () =>
      import('./pages/club/class/class.module').then((m) => m.ClassPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'participant/:id',
    loadChildren: () =>
      import('./pages/owner/participant/participant.module').then(
        (m) => m.ParticipantPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'owner/class/:id',
    loadChildren: () =>
      import('./pages/owner/class/class.module').then((m) => m.ClassPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'owner/rooms',
    loadChildren: () =>
      import('./pages/owner/rooms/rooms.module').then((m) => m.RoomsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'owner/room/:id',
    loadChildren: () =>
      import('./pages/owner/room/room.module').then((m) => m.RoomPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'owner/club',
    loadChildren: () =>
      import('./pages/owner/club/club.module').then((m) => m.ClubPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'club/news',
    loadChildren: () =>
      import('./pages/owner/news/news.module').then((m) => m.NewsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'owner/classes',
    loadChildren: () =>
      import('./pages/owner/classes/classes.module').then(
        (m) => m.ClassesPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'owner/participants',
    loadChildren: () =>
      import('./pages/owner/participants/participants.module').then(
        (m) => m.ParticipantsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'owner/newsitem/:id',
    loadChildren: () =>
      import('./pages/owner/newsitem/newsitem.module').then(
        (m) => m.NewsitemPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'news/:id',
    loadChildren: () =>
      import('./pages/club/news/news.module').then((m) => m.NewsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'code',
    loadChildren: () =>
      import('./pages/club/code/code.module').then((m) => m.CodePageModule),
  },
  {
    path: 'code/:id',
    loadChildren: () =>
      import('./pages/club/code/code.module').then((m) => m.CodePageModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'owner/code',
    loadChildren: () =>
      import('./pages/owner/code/code.module').then((m) => m.CodePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'forgot',
    loadChildren: () => import('./pages/auth/forgot/forgot.module').then( m => m.ForgotPageModule)
  },
  {
    path: 'activities',
    loadChildren: () => import('./pages/club/activities/activities.module').then( m => m.ActivitiesPageModule)
  },
  {
    path: 'week',
    loadChildren: () => import('./pages/admin/week/week.module').then( m => m.WeekPageModule)
  },
  {
    path: 'subscription/:id',
    loadChildren: () => import('./pages/owner/subscription/subscription.module').then( m => m.SubscriptionPageModule)
  },
  {
    path: 'subscriptions',
    loadChildren: () => import('./pages/owner/subscriptions/subscriptions.module').then( m => m.SubscriptionsPageModule)
  },
  {
    path: 'invoice/:id',
    loadChildren: () => import('./pages/owner/invoice/invoice.module').then( m => m.InvoicePageModule)
  },
  {
    path: 'invoices',
    loadChildren: () => import('./pages/owner/invoices/invoices.module').then( m => m.InvoicesPageModule)
  },
  {
    path: 'payment/:id',
    loadChildren: () => import('./pages/owner/payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('./pages/owner/payments/payments.module').then( m => m.PaymentsPageModule)
  },
  {
    path: 'finance',
    loadChildren: () => import('./pages/owner/finance/finance.module').then( m => m.FinancePageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/club/payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'chats',
    loadChildren: () => import('./pages/owner/chats/chats.module').then( m => m.ChatsPageModule)
  },
  {
    path: 'chat/:id/:title',
    loadChildren: () => import('./pages/club/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/admin/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'user/:id',
    loadChildren: () => import('./pages/admin/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'admin/clubs',
    loadChildren: () => import('./pages/admin/clubs/clubs.module').then( m => m.ClubsPageModule)
  },
  {
    path: 'admin/club/:id',
    loadChildren: () => import('./pages/admin/club/club.module').then( m => m.ClubPageModule)
  },
  {
    path: 'admin/code/:email',
    loadChildren: () => import('./pages/admin/code/code.module').then( m => m.CodePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
