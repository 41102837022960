import { Component, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AuthService } from './services/auth.service';
import { Platform } from '@ionic/angular';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Router } from '@angular/router';
import { ThemeService } from './services/theme.service';
import { SharedService } from './services/shared.service';
import { ToastController } from '@ionic/angular';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

if (environment.production) {
  window.console.log = () => {}; // in prod disable console.log
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public themeColor = [
    { name: 'Default', class: 'default' },
    { name: 'Dark', class: 'dark-theme' },
    { name: 'Purple', class: 'purple' },
    { name: 'Green', class: 'green' },
  ];
  public selectTheme;
  public deflang = environment.defaultLang;

  constructor(
    public translate: TranslateService, // private pushService: PushService
    public authService: AuthService,
    public shared: SharedService,
    public platform: Platform,
    private toastController: ToastController,
    private zone: NgZone,
    private router: Router,
    private theme: ThemeService
  ) {
    const preferredLanguageBrowser = navigator.language;
    console.log(preferredLanguageBrowser);
    if (preferredLanguageBrowser.includes('US')) {
      const localmetric = localStorage.getItem('djcmetric');
      if (!localmetric) {
        localStorage.setItem('djcmetric', 'imperial');
      }
    }

    let native = this.shared.getDefaultLanguage();
    if (!native) {
      native = environment.defaultLang;
    }
    this.deflang = native;
    this.translate.setDefaultLang(native);

    const ltheme = localStorage.getItem('ltheme');
    if (ltheme) {
      console.log('theme found', ltheme);
      this.selectTheme = ltheme;
    } else {
      console.log('theme not found');
      this.selectTheme = 'default';
    }

    this.dynamicTheme();
    if (this.platform.is('capacitor')) {
      this.initializeApp();
      console.log('native app running');
    }
    // localStorage.setItem('djcregistrationToken', 'ASDASJJJASJDJAJADJASD');
    // console.log('Push action performed: ' + 'ASDASJJJASJDJAJADJASD');
    // setTimeout(()=>{
    //   this.presentToast('Hello Oscar, how are you doing?','/my');
    // });
  }
  route() {
    return this.router.url;
  }
  routeContains(str: string): boolean {
    return this.router.url.includes(str);
  }
  dynamicTheme() {
    this.theme.activeTheme(this.selectTheme);
  }
  changeLang() {
    this.translate.setDefaultLang(this.deflang);
    this.shared.setDefaultLanguage(this.deflang);
  }

  async presentToast(message, link) {
    const toast = await this.toastController.create({
      message,
      duration: 6000,
      position: 'top',
      buttons: [
        {
          icon: 'eye',
          role: 'info',
          handler: () => {
            this.router.navigateByUrl(link);
          },
        },
        {
          icon: 'close',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = environment.domain;
        const pathArray = event.url.split(domain);
        // Get the last element with pop()
        const appPath = pathArray.pop();
        if (appPath) {
          this.router.navigateByUrl(appPath);
        }
      });
    });
    this.checkThePush();
  }
  version() {
    const envvar = environment.version + '';
    const envprod = environment.production;
    const lng = envvar.length;
    let nstr = '';
    for (let index = 0; index < envvar.length; index++) {
      if (index > 0) {
        nstr += '.';
      }
      nstr += envvar.substring(index, index + 1);
    }
    if (envprod) {
      nstr += ' -- Production build';
    } else {
      nstr += ' -- Development build';
    }
    return nstr;
  }

  async registerPushNotifications() {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
  }

  getDeliveredNotifications = async () => {
    const notificationList =
      await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
  };

  //push the message
  private checkThePush() {
    console.log('Platform used for push service', this.platform.platforms());
    if (this.platform.is('cordova') || this.platform.is('capacitor')) {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting

      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token: Token) => {
        console.log('Push registration success, token: ' + token.value);
        localStorage.setItem('djcregistrationToken', token.value);
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          console.log('Push received: ' + JSON.stringify(notification));
          this.presentToast(notification.body, notification.link);
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (tap: ActionPerformed) => {
          console.log('Push action performed: ' + JSON.stringify(tap));
          this.router.navigateByUrl(tap.notification.link);
        }
      );
    }
  }
}
